import React from 'react'
import "./Team.css"
import Team1 from "../../components/compopages/Team.jsx"
const Team = () => {
  return (
    <>
       <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Our Team</h1>
                </div>
            </div>
        </div>
        <div className='mt-5'>
<Team1/>
        </div>
    </>
  )
}

export default Team