import React, { useEffect, useState} from 'react';
import "./Header.css"
import 'aos/dist/aos.css'; // Import AOS CSS file
import AOS from 'aos';
const Header = () => {
  const [count,  setCount] = useState(1);
  const [projcount, projsetCount] = useState(1);
  const [bcount, bsetCount] = useState(1);
  const [ccount, csetCount] = useState(1);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); 

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 874) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [count]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (projcount < 162) {
        projsetCount(projcount + 1);
      } else {
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [projcount]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (bcount < 268) {
        bsetCount(bcount + 1);
      } else {
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [bcount]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (ccount < 395) {
        csetCount(ccount + 1);
      } else {
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [ccount]);

  return (
    <>
<section id="hero" className="hero d-flex align-items-center">
    <div className="container">
      <div className="row gy-4 d-flex justify-content-between">
        <div className="col-lg-10 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h2  data-aos="zoom-out-left">Welcome to <i>DivCodeX</i> <span className='privateli'>(Private Limited)</span></h2>
          <p data-aos="fade-up" data-aos-delay="100">Empower your digital presence with DivCodeX (Private Limited) - where innovation meets excellence. From Website Development, SEO, Mobile Applications Development, Web Application Development and Software Development. we tailor solutions to elevate your brand. Let us transform your ideas into impactful online experiences</p>

          <div className="row gy-4" data-aos="fade-up" data-aos-delay="400">

            <div className="col-lg-3 col-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="purecounter">{count} +</span>
                <p>Total Projects</p>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter">{projcount} +</span>
                <p>Websites</p>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter">{bcount} +</span>
                <p>Mobile Application</p>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter">{ccount} +</span>
                <p>Other Develpoments</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Header