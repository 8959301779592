import React, { useEffect } from 'react';
import AOS from 'aos';
import {Helmet} from "react-helmet";
import "./Software_Development.css"
import { Link } from 'react-router-dom'
import Cta from '../../components/compopages/Cta'
const Software_Development = () => {
  useEffect(() => {
    AOS.init({

    });
  }, []);
  return (
  <>
           <Helmet>
        {/* SEO Title */}
        <title>Professional Software Development Services | Divcodex (Private Limited)</title>

        {/* Meta Description */}
        <meta name="description" content="Divcodex (Private Limited) offers professional software development services, specializing in custom software, web applications, and enterprise solutions." />

        {/* Meta Keywords (Use sparingly) */}
        <meta name="keywords" content="software development, custom software, web applications, enterprise solutions, IT services, Divcodex (Private Limited)" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Professional Software Development Services | Divcodex (Private Limited)" />
        <meta property="og:description" content="Divcodex (Private Limited) provides expert software development services, building custom software and enterprise solutions." />
        <meta property="og:image" content="https://example.com/software-development-image.jpg" />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Software_Development" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Software Development Services | Divcodex (Private Limited)" />
        <meta name="twitter:description" content="Divcodex (Private Limited) offers professional software development services with a focus on custom solutions for businesses." />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Software_Development" />
      </Helmet>
   <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Software Development</h1>
                </div>
            </div>
        </div>
    <section id="why-us" class="why-us section-bg">
      <div class="container-fluid" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

            <div class="content">
              <h3>DivCoxdex <strong>Where Innovation Meets Excellence in Software Development</strong></h3>
              <p>Divcodex (Private Limited) has been a leader in providing tailored software solutions to the all sector for over 5 years, Specializing in Real Estate & Highrise Building Projects. We are dedicated to offering advanced software development services that meet the unique demands of the all kind of  industry. Our team harnesses the latest technologies to create software applications that address specific business needs.   </p>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <Link className='a11 collapsed' data-bs-toggle="collapse"  data-bs-target="#accordion-list-1"><span>01</span> Custom Software Development <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></Link>
                  <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                    "Divcodex (Private Limited): Custom software development to meet your unique business challenges." </p>
                  </div>
                </li>

                <li>
                  <Link   className='a11 collapsed'data-bs-toggle="collapse" data-bs-target="#accordion-list-2" ><span>02</span>UI/UX Design <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></Link>
                  <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                    At Divcodex (Private Limited), we prioritize user-friendly design, ensuring our applications feature a best-in-class Graphical User Interface (GUI) for a seamless and intuitive user experience. Our commitment to innovation and excellence drives us to deliver software solutions that not only meet but often exceed client expectations. Trust Divcodex to provide innovative, efficient, and customized software solutions for your real estate business.</p>                  </div>
                </li>

                <li>
                  <Link  className='a11 collapsed' data-bs-toggle="collapse" data-bs-target="#accordion-list-3"><span>03</span> Quality Assurance & Testing:<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></Link>
                  <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                    "Divcodex (Private Limited): Ensuring quality through customized software testing and quality assurance services."</p>
                  </div>
                </li>

              </ul>
            </div>

          </div>

          <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img bd-img"  data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
        </div>

      </div>
    </section>

 <section id="skills" class="skills">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
            <img src={process.env.PUBLIC_URL + '/Images/software_development_divcodex.png'} class="img-fluid" alt="Divcodex_Software_Development"/>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
            <h3> <strong>Divcodex (Private Limited):</strong>Where Innovation Meets Excellence in Front-End Development</h3>
            <p class="fst-italic">
            At Divcodex (Private Limited), we are dedicated to crafting captivating and user-friendly front-end solutions that elevate your online presence and drive engagement. With a fusion of creativity, technical prowess, and a commitment to innovation, we specialize in delivering bespoke front-end experiences tailored to meet the unique needs of our clients.
            </p>

            <div class="skills-content">

              <div class="progress">
                <span class="skill">HTML <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">CSS <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">JavaScript <i class="val">75%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">Bootstrap <i class="val">55%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="100" aria-valuemax="100"></div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </section>
<Cta/>  
    </>
  )
}

export default Software_Development