import React, { useEffect } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom'
import "./Cta.css"
const Cta = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      useEffect(() => {
        AOS.init({
    
        });
      }, []);
  return (
   <>
     <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3>Ask for Query</h3>
            <p>"Unlock the potential of your projects with DivCodex (Private Limited), your premier software development company. Crafted solutions tailored to your needs."</p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <Link className="a11 cta-btn align-middle" onClick={scrollToTop} to="/DivCodeX_Applynow">Ask for Quotation</Link>
          </div>
        </div>

      </div>
    </section>
   </>
  )
}

export default Cta