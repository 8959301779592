import React from 'react'
import "./Products.css";
import { Helmet } from 'react-helmet';
import Products1 from '../../components/compopages/Products'
const Products = () => {
  return (
    <>
     <Helmet>
        {/* SEO Title Tag */}
        <title>Innovative IT and Software Products | DivCodeX</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Discover innovative IT and software products from DivCodeX (Private Limited). We offer cutting-edge solutions for businesses, including software tools, hardware devices, and more."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Products" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Innovative IT and Software Products | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="Explore a wide range of innovative IT and software products from DivCodeX (Private Limited). From software tools to hardware devices, we offer solutions to meet your business needs."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Products" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Innovative IT and Software Products | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) offers innovative IT and software products, including software tools, hardware devices, and custom solutions for businesses."
        />
      </Helmet>
        <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Products</h1>
                </div>
            </div>
        </div>
        <div className='mt-5'>
<Products1/>
        </div>
    </>
  )
}

export default Products