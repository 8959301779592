import React,{useState , useRef, useEffect} from 'react'
import "./Bootcamps.css"
import emailjs from '@emailjs/browser';
const Bootcamps = () => {
    function myFunc() { 
        window.location.href = "https://www.divcodex.com"; 
    } 
        const form = useRef();
    
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs
            .sendForm('service_7gneq5m', 'template_r9r4cag', form.current, {
              publicKey: '5Pph3JzVIUrkvHz1z',
            })
            .then(
              () => {
                console.log('SUCCESS!');
                alert('Thanks For Apply Bootcamp Contact You Shortly'); 
                myFunc();
       
              },
              (error) => {
                console.log('FAILED...', error.text);
              },
            );
        };
    const [countries, setCountries] = useState([]);
    
    useEffect(() => {
      fetchCountries();
    }, []);
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countriesData = data.map(country => ({
          name: country.name.common,
          code: country.cca2
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
  return (
    <>
    <div className="container register">
                <div className="row">
                    <div className="col-md-3 register-left">
                    
                        <h3 className='text-white'>Welcome  (Private Limited)</h3>
                    </div>
                    <div className="col-md-9 register-right">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h3 className="register-heading">Apply for Bootcamp</h3>
                                <form className="row register-form"  ref={form} onSubmit={sendEmail}>
                                    <div className="col-md-6 ">
                                        <div className="form-group mb-2">
                                        <label class="form-label">First Name</label>
                                            <input type="text" name='stdname' className="form-control" placeholder="First Name*" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Age</label>
                                            <input type="number"  name='age' className="form-control" placeholder="Your Age*" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Phone No</label>
                                        <input type="number"  minlength="10" maxlength="10" name="number" className="form-control" placeholder="Your Phone *" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Bootcamp Time</label>
                                            <input type="text" className="form-control" placeholder="3 Month" value="3 Month" disabled required />
                                            <input type="text" className="form-control"  value="3 Month" hidden required />

                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Country</label>
                                        <select className="form-select" name='contry' required id="country">
        <option value="">Select Country</option>
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
                                        </div>
                                        <div className="form-group mb-2 p-2">
                                        <h5 >Education</h5>
                                    </div>
                                    <div className="form-group mb-2">
                                    <label class="form-label">Dgree</label>
                                            <input type="text"  name='Dgree' className="form-control" placeholder="MCS FSC .. etc" required />
                                        </div>
                                        <div className="form-group mb-2">
                                    <label class="form-label">Total Marks</label>
                                            <input type="number"  name='totalmarks' className="form-control" placeholder="700 800 etc*" required />
                                        </div>
                                        <div className="form-group mb-2">
                                    <label class="form-label">School/College</label>
                                            <input type="text" name='school'  className="form-control" placeholder="Punjab uni..etc*" required />
                                        </div>
                                    
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label class="form-label">Last Name</label>
                                            <input type="text" name='stdlname' className="form-control" placeholder="Last Name*" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Gender</label>
                                            <select className="form-select" name='gender'>
                                                <option className="hidden"    selected disabled>Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Email</label>
                                            <input type="email" name='Email' className="form-control" placeholder="Your Email *" />
                                        </div>
                                        <div className="form-group mb-2">
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Intrusted Bootcamp</label>
                                            <select className="form-select" name='bootcamp'>
                                                <option className="hidden"  selected disabled>Select Bootcamp</option>
                                                <option value="Web Application Development">Web Application Development</option>
                                                <option value="Website Development">Website Development</option>
                                                <option value="SEO Optimization">SEO Optimization</option>
                                                <option value="Ads Marketing">Ads Marketing</option>
                                                <option value="Digital Marketing">Digital Marketing</option>
                                                <option value="Shopify">Shopify</option>
                                                <option value="Amazon">Amazon</option>
                                                <option value="E-Bay">E-Bay</option>
                                                <option value="Dropshipping">Dropshipping</option>
                                                <option value="TikTok Shop Course">TikTok Shop Course</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">City</label>
                                            <input type="text" className="form-control" name='city' placeholder="Enter Your city *" />
                                        </div>
                                        <div className="form-group mb-2 p-2">
                                        <h5 >Kindly Enter You last Degree</h5>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label class="form-label">Subjects</label>
                                            <input type="text" name='Subjects' className="form-control" placeholder="Computer ..etc" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">obtain Marks</label>
                                            <input type="number" name='obtainmarks' className="form-control" placeholder="350 etc" required />
                                        </div>
                                        <div className="form-group mb-2">
                                        <label class="form-label">Grade</label>
                                           <select name="grade" className='form-select' required>
                                            <option value="">Select Grade</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="D">C</option>
                                            <option value="D">D</option>
                                           </select>
                                        </div>
                                        <input type="submit" className="btnRegister"  value="Apply Now"/>
                                   
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    
    
    </>
  )
}

export default Bootcamps