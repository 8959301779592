import React from 'react'
import "./Careers.css"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
const Careers = () => {
  return (
    <>
     <Helmet>
        {/* SEO Title Tag */}
        <title>Careers at DivCodeX (Private Limited) | Join Our Team</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Explore career opportunities at DivCodeX (Private Limited). We're looking for talented professionals in software development, IT, and other fields. Join our team and grow your career with us."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Careers" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Careers at DivCodeX (Private Limited) | Join Our Team" />
        <meta
          property="og:description"
          content="Looking to join DivCodeX (Private Limited)?  Explore our career opportunities in software development, IT, and more. Learn about our company culture and apply today."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Careers" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Careers at DivCodeX (Private Limited) | Join Our Team" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) offers exciting career opportunities. Learn about our open positions in software development, IT, and more. Join our team and grow with us."
        />
      </Helmet>
    <div className="container-fluid bg-dark bg-header ">
    <div className="row py-2">
        <div className="col-12  text-center">
            <h1 className="display-5 text-white">Join us </h1>
        </div>
    </div>
</div>
<div className='mt-5'>
<div className=' container-xxl p-4 '>
  <div className='w-100'>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1 active" id="lead-tab" data-bs-toggle="tab" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="true"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_UAE.jpg'} alt="TS_Solutions_Hub_UAE" /></button>
        </li>
        <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1" id="FollowUpLeads-tab" data-bs-toggle="tab" data-bs-target="#FollowUpLeads" type="button" role="tab" aria-controls="FollowUpLeads" aria-selected="false"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_Pakistan.jpeg'} alt="TS_Solutions_Hub_Pakistan" /></button>
        </li>
        <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1" id="LeadsStatus-tab" data-bs-toggle="tab" data-bs-target="#LeadsStatus" type="button" role="tab" aria-controls="LeadsStatus" aria-selected="false"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_UK.jpg'} alt="TS_Solutions_Hub_UK" /></button>
        </li>
    </ul>
    </div>
    <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active p-4" id="lead" role="tabpanel" aria-labelledby="lead-tab">
        <h5>Please send your resume to this email address for consideration for future opportunities in <b>UAE.</b></h5>
        <Link><i>into@divcodex.com</i></Link>
<p>Thank you for your interest in joining our team at DivCodeX (Private Limited). We currently do not have any available positions that align with your skills, and we encourage you to continue exploring career opportunities with us.</p>
    </div>
        <div className="tab-pane fade p-4" id="FollowUpLeads" role="tabpanel" aria-labelledby="FollowUpLeads-tab">
        <h5>Please send your resume to this email address for consideration for future opportunities in <b>Pakistan.</b></h5>
        <Link><i>into@divcodex.com</i></Link>
<p>Thank you for your interest in joining our team at DivCodeX (Private Limited). We currently do not have any available positions that align with your skills, and we encourage you to continue exploring career opportunities with us.</p>
          </div>
        <div className="tab-pane fade p-4" id="LeadsStatus" role="tabpanel" aria-labelledby="LeadsStatus-tab">
        <h5>Please send your resume to this email address for consideration for future opportunities in <b>USA</b></h5>
        <Link><i>into@divcodex.com</i></Link>
<p>Thank you for your interest in joining our team at DivCodeX (Private Limited). We currently do not have any available positions that align with your skills, and we encourage you to continue exploring career opportunities with us.</p>

         </div>
    </div>
</div> 
</div>
</>
  )
}

export default Careers