import React, { useEffect ,useRef } from 'react';
import AOS from 'aos';
import "./Contactus.css"
import emailjs from '@emailjs/browser';

const Contactus = () => { 
  useEffect(() => {
    AOS.init({

    });
  }, []);
  function myFunc() { 
    window.location.href = "https://www.divcodex.com"; 
} 
    
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7gneq5m', 'template_1qc8il7', form.current, {
        publicKey: '5Pph3JzVIUrkvHz1z',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        alert("Your Query has been Reacived Thanks");
        myFunc();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
    return (
    <>
<div className=' container-xxl p-4 '>
  <div className='w-100'>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1 active" id="lead-tab" data-bs-toggle="tab" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="true"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_UAE.jpg'} alt="TS_Solutions_Hub_UAE" /></button>
        </li> */}
        <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1" id="FollowUpLeads-tab" data-bs-toggle="tab" data-bs-target="#FollowUpLeads" type="button" role="tab" aria-controls="FollowUpLeads" aria-selected="false"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_Pakistan.jpeg'} alt="TS_Solutions_Hub_Pakistan" /></button>
        </li>
        {/* <li className="nav-item nav-item1" role="presentation">
            <button className="nav-link nav-link1" id="LeadsStatus-tab" data-bs-toggle="tab" data-bs-target="#LeadsStatus" type="button" role="tab" aria-controls="LeadsStatus" aria-selected="false"><img className='contimg' src={process.env.PUBLIC_URL + '/Images/Contactus/TS_Solutions_Hub_UK.jpg'} alt="TS_Solutions_Hub_UK" /></button>
        </li> */}
    </ul>
    </div>
    <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active p-4" id="lead" role="tabpanel" aria-labelledby="lead-tab">
      <div id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="row gx-lg-0 gy-">

          <div className="col-lg-4">

            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location Pakistan:</h4>
                  <p>Al-Rehman Garden Phase#2 Gat#1, Lahore, Office 88</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@divcodex.com</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+92 321 0410033</p>
                  <p>+92 321 0510033</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 10AM - 7PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            <form ref={form} onSubmit={sendEmail} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                <label class="form-label">Full Name</label>
                  <input type="text" name="customer_name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                
                <div className="col-md-6 form-group mt-3 mt-md-0">
                <label class="form-label">Email</label>
                  <input type="email" className="form-control" name="Email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
              <label class="form-label">Subject</label>
                <input type="text" className="form-control" name="Subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="row">
                <div className="form-group mt-3">
                <label class="form-label">Phone No</label>
                  <input type="number" name="mobile_number" className="form-control" id="name" placeholder="Your Number" required/>
                </div>
              </div>
              <div className="form-group mt-3">
              <label class="form-label">Address</label>
                  <input type="text" className="form-control" name="addresss" id="email" placeholder="Your Address" required/>
                </div>
        
              <div className="form-group mt-3">
              <label class="form-label">Message</label>
                <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
              </div>
  
              <input className='btnRegister' type="submit" value="Send" />
              
            </form>
          </div>

        </div>

      </div>
    </div>
    </div>
        {/* <div className="tab-pane fade p-4" id="FollowUpLeads" role="tabpanel" aria-labelledby="FollowUpLeads-tab">
        <div id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="row gx-lg-0 gy-">

          <div className="col-lg-4">

            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location Pakistan:</h4>
                  <p>A108 Adam Street, New York, NY 535022</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@example.com</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 11AM - 23PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button className='text-black' type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </div>  
          </div>
        <div className="tab-pane fade p-4" id="LeadsStatus" role="tabpanel" aria-labelledby="LeadsStatus-tab">
        <div id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="row gx-lg-0 gy-">

          <div className="col-lg-4">

            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location USA:</h4>
                  <p>A108 Adam Street, New York, NY 535022</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@example.com</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+1 5589 55488 55</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h4>Open Hours:</h4>
                  <p>Mon-Sat: 11AM - 23PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-8">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button className='text-black' type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </div> 
         </div> */}
    </div>
</div> 
    </>
  )
}

export default Contactus