import React from 'react'
import "./Home.css"
import {Helmet} from "react-helmet";
import Header from '../../components/MainHeader/Header'
import Aboutus from '../../components/compopages/Aboutus'
import CEO1 from '../../components/compopages/CEO.jsx'
import Services from '../../components/compopages/Services.jsx'
import Products from '../../components/compopages/Products.jsx'
// import Team from '../../components/compopages/Team.jsx'
import Contactus from '../../components/compopages/Contactus.jsx'
const Home = () => {
  return (
   <>
     <Helmet>
        <title>Pakistan's Top IT Company | Divcodex (Private Limited) - Software & IT Solutions</title>
        <meta name="description" content="Divcodex provides top-notch software and IT services in Pakistan. Innovative solutions for businesses of all sizes. Contact us today!" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Pakistan's Top IT Company | Divcodex (Private Limited) - Software & IT Solutions" />
        <meta property="og:description" content="Divcodex (Private Limited) provides top-notch software and IT services in Pakistan. Innovative solutions for businesses of all sizes." />
        <meta property="og:url" content="https://www.divcodex.com/" /> {/* Replace with your website's URL */}

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Pakistan's Top IT Company | Divcodex - Software & IT Solutions" />
        <meta name="twitter:description" content="Divcodex provides top-notch software and IT services in Pakistan. Innovative solutions for businesses of all sizes." />
      </Helmet>
   <Header/>
   <div className='mt-5'>
   <Aboutus/>
   </div>
   <div>
<CEO1/>
   </div>
   <div className="container-xxl py-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="row">
            <div className="section-title text-center position-relative mx-auto headhom">
                <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                <h1 className="mb-0">DivCodeX (Private Limited) Solutions for Your Successful Business</h1>
            </div>
            </div>
            </div>
            <div>
<Services/>
   </div>
 
   <div className="container-xxl py-4 mt-4 wow fadeInUp" data-wow-delay="0.1s">
   <hr className='row p-4' />
        <div className="row">
            <div className="section-title text-center position-relative mx-auto headhom">
                <h5 className="fw-bold text-primary text-uppercase">Our Products</h5>
                <h1 className="mb-0">DivCodeX (Private Limited) Products for Your Successful Business</h1>
            </div>
            </div>
            </div>
            <div>
              <Products/>
            </div>
            <div>
            </div>
            <div className="container-xxl py-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="row">
            <div className="section-title text-center position-relative mx-auto headhom">
                <h1 className="mb-0">Contact US</h1>
                <p className="text-primary ">DivCodeX (Private Limited) has 1 facilities Worldwide, details of our globle footprint witch is located Pakistan was as Following</p>

            </div>
            </div>
            </div>
            <div>
              <Contactus/>
            </div>


   </>
  )
}

export default Home