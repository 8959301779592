import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Hardware_Network.css"
import { Helmet } from 'react-helmet';
import Cta from '../../components/compopages/Cta';

const Ads_Marketing = () => {
    useEffect(() => {
        AOS.init({
    
        });
      }, []);
  return (
    <>
       <Helmet>
        {/* SEO Title Tag */}
        <title>Ads Marketing Services | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) offers comprehensive ads marketing services, including digital advertising, PPC campaigns, social media marketing, and more. Contact us to boost your online visibility."
        />

        {/* Meta Keywords (use sparingly) */}
        <meta
          name="keywords"
          content="ads marketing, digital advertising, PPC campaigns, social media marketing, online advertising, DivCodeX (Private Limited)"
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Ads_Marketing" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Ads Marketing Services | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="DivCodeX (Private Limited) offers comprehensive ads marketing services. We specialize in digital advertising, PPC campaigns, and social media marketing. Contact us to improve your online presence."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Ads_Marketing" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ads Marketing Services | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) offers comprehensive ads marketing services, including digital advertising, PPC campaigns, and social media marketing. Boost your online presence with our expert team."
        />
      </Helmet>
          <section id="hero11">
    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active bg3" >
          <div class="carousel-container">
            <div class="carousel-content animate__animated animate__fadeInUp">
              <h2>Unlock the Future of Ads Marketing with <span>Divcodex(Private Limited)</span></h2>
              <p>Are we discussing a software product, a website, an app, or a marketing campaign? Below are some general suggestions for marketing-related features in different contexts:</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="services1" class="services1">
      <div class="container">

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up">
              <div class="icon"><i class="bi bi-briefcase"></i></div>
              <h4 class="title">Customer Relationship Management (CRM) Integration</h4>
              <p class="description">Integrate a CRM feature to track customer interactions, manage leads, and segment audiences. This can be valuable for targeted marketing campaigns and personalized customer experiences.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bi bi-card-checklist"></i></div>
              <h4 class="title">Email Marketing Automation</h4>
              <p class="description">Add a feature that allows automated email campaigns. This can include personalized emails, automated drip campaigns, or event-triggered emails to engage customers at different stages of the sales funnel.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bi bi-bar-chart"></i></div>
              <h4 class="title">Video Marketings</h4>
              <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bi bi-binoculars"></i></div>
              <h4 class="title">Social Media Integration</h4>
              <p class="description">Include tools for creating and sharing video content. Video marketing is an effective way to engage audiences, and this feature could include basic video editing tools, templates, or direct integration with video platforms like YouTube or Vimeo.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="bi bi-brightness-high"></i></div>
              <h4 class="title">Analytics Dashboard</h4>
              <p class="description">Include a feature that provides detailed analytics on user behavior, engagement, and conversion rates. This data can guide marketing strategies and help identify areas for improvement.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><i class="bi bi-calendar4-week"></i></div>
              <h4 class="title">Content Personalization</h4>
              <p class="description">Add a feature for personalizing content based on user preferences, past interactions, or demographics. This can improve customer engagement and conversion rates.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Cta/>
    </>
  )
}

export default Ads_Marketing