import React,{ useState}  from "react";
import {  NavLink } from "react-router-dom";
import "./Navbar.css"
const Navbar = () =>{
  const [navbarOpen, setNavbarOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };
  const navfun = () =>{
    handleNavLinkClick();
    scrollToTop();

  }

return(
<>
<nav className={`navbar navbar-expand-lg bg-dp navbar-dark sticky-top p-0 shadow-sm ${navbarOpen ? 'open' : ''}`} aria-label="Fourth navbar example">
        <div className="container-xxl">
          <NavLink className="navbar-brand" onClick={navfun} to="/">
            <img className='logo11' src={process.env.PUBLIC_URL + '/Images/DivCodeX_logo.png'} alt="Pakistan Top Software Company, Top Ten All kind of Software, Websites, Web Application, SEO , digital marketing" />
          </NavLink>
          <button className="navbar-toggler" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarsExample04">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
          <li className="nav-item">
            <NavLink className="nav-link" aria-current="page" onClick={navfun} to="/">Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" onClick={navfun} to="/DivCodeX_About-Us">About</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" onClick={navfun} to="/DivCodeX_Services">Services</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" onClick={navfun} to="DivCodeX_Products">Products</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" onClick={navfun} to="/DivCodeX_Bootcamps">Bootcamps</NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Portfolio">Portfolio</NavLink>
          </li> */}
          {/* <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/DivCodeX_Team">Team</NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/DivCodeX_Careers">Careers</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/DivCodeX_ContactUs">Contact Us</NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Sitemap"><i className="bi bi-diagram-3-fill"></i></NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  </nav>
   </>




);

}
export default Navbar;