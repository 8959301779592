import React, { useEffect } from 'react';
import "./Bootcamps.css"
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import { Link } from 'react-router-dom'
const Bootcamps = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    AOS.init({

    });
  }, []);
  return (
    <>
     <Helmet>
        {/* SEO Title Tag */}
        <title>Technology Bootcamps | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX offers technology bootcamps, providing training in software development, data science, and IT skills. Join our bootcamps to kickstart your tech career."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Bootcamps" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Technology Bootcamps | DivCodeX" />
        <meta
          property="og:description"
          content="Join DivCodeX's (Private Limited) technology bootcamps to gain skills in software development, data science, and IT. Our bootcamps are designed to jumpstart your tech career."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Bootcamps" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Technology Bootcamps | DivCodeX" />
        <meta
          name="twitter:description"
          content="DivCodeX's technology bootcamps offer training in software development, data science, and IT. Start your tech journey with our bootcamps."
        />
      </Helmet>
         <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Bootcamps</h1>
                </div>
            </div>
        </div>
       
        <div className=' container-xxl p-4 '>
  <div className='w-100'>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* <li className="nav-item" role="presentation">
            <button className="nav-link active" id="lead-tab" data-bs-toggle="tab" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="true">United Arab Emirates</button>
        </li>
        <li className="nav-item" role="presentation">
            <button className="nav-link" id="FollowUpLeads-tab" data-bs-toggle="tab" data-bs-target="#FollowUpLeads" type="button" role="tab" aria-controls="FollowUpLeads" aria-selected="false">United States America</button>
        </li> */}
        <li className="nav-item" role="presentation">
            <button className="nav-link active" id="LeadsStatus-tab" data-bs-toggle="tab" data-bs-target="#LeadsStatus" type="button" role="tab" aria-controls="LeadsStatus" aria-selected="false">Pakistan</button>
        </li>
    </ul>
    </div>
    <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade  p-4" id="lead" role="tabpanel" aria-labelledby="lead-tab">
      {/* <div id="values" class="values">
<div class="container" data-aos="fade-up">
  <header class="section-header">
    <h2>United Arab Emirates</h2>
  </header>

  <div class="row">

    <div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-3.png'}  class="img-fluid" alt=""/>
        <h3>Ad cupiditate sed est odio</h3>
        <p>Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit. Et veritatis id.</p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-1.png'}  class="img-fluid" alt=""/>
        <h3>Voluptatem voluptatum alias</h3>
        <p>Repudiandae amet nihil natus in distinctio suscipit id. Doloremque ducimus ea sit non.</p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-2.png'}  class="img-fluid" alt=""/>
        <h3>Fugit cupiditate alias nobis.</h3>
        <p>Quam rem vitae est autem molestias explicabo debitis sint. Vero aliquid quidem commodi.</p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-2.png'}  class="img-fluid" alt=""/>
        <h3>Fugit cupiditate alias nobis.</h3>
        <p>Quam rem vitae est autem molestias explicabo debitis sint. Vero aliquid quidem commodi.</p>
      </div>
    </div>
  </div>

</div>

</div> */}
      
    </div>
        <div className="tab-pane fade p-4" id="FollowUpLeads" role="tabpanel" aria-labelledby="FollowUpLeads-tab">
  
      {/* <div id="values" class="values">
<div class="container" data-aos="fade-up">
  <header class="section-header">
    <h2>United States America</h2>
  </header>

  <div class="row">

    <div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-3.png'}  class="img-fluid" alt=""/>
        <h3>Ad cupiditate sed est odio</h3>
        <p>Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit. Et veritatis id.</p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-1.png'}  class="img-fluid" alt=""/>
        <h3>Voluptatem voluptatum alias</h3>
        <p>Repudiandae amet nihil natus in distinctio suscipit id. Doloremque ducimus ea sit non.</p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-2.png'}  class="img-fluid" alt=""/>
        <h3>Fugit cupiditate alias nobis.</h3>
        <p>Quam rem vitae est autem molestias explicabo debitis sint. Vero aliquid quidem commodi.</p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/values-2.png'}  class="img-fluid" alt=""/>
        <h3>Fugit cupiditate alias nobis.</h3>
        <p>Quam rem vitae est autem molestias explicabo debitis sint. Vero aliquid quidem commodi.</p>
      </div>
    </div>
  </div>



</div>
      </div>  */}
          </div>
        <div className="tab-pane fade p-4 show active" id="LeadsStatus" role="tabpanel" aria-labelledby="LeadsStatus-tab">
        <div id="values" class="values">
<div class="container" data-aos="fade-up">
  <div class="row">

    <div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_web-developments.png'}  class="img-fluid" alt="Divcodex_Bootcamps_web-developments"/>
        <h3>Web Application Development</h3>
        <p>Beginner Full Stack Web Development: HTML, CSS, ASP.Net Core 8 MVC , C# <Link  onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_website-developments.png'}  class="img-fluid" alt="Divcodex_Bootcamps_website-developments"/>
        <h3>Website Development</h3>
        <p>HTML5, CSS, Bootstrap, Javascript, jQuery and React js for Website Development. <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>

    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_SEO Optimization.jpg'}  class="img-fluid" alt="Divcodex_Bootcamps_SEO Optimization"/>
        <h3>SEO Optimization</h3>
        <p>Learn SEO basics and advanced techniques with our comprehensive online and Physical course from DivCodeX (Private Limited). <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_Ads_Marketing.jpg'}  class="img-fluid" alt="Divcodex_Bootcamps_Ads_Marketing"/>
        <h3>Ads Marketing</h3>
        <p>Master the art of advertising and marketing strategies with our dynamic online course from DivCodeX (Private Limited) Bootcamp. <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_Digital_Marketing.jpg'}  class="img-fluid" alt="Divcodex_Bootcamps_Digital_Marketing"/>
        <h3>Digital Marketing</h3>
        <p>Unlock the potential of digital marketing with our expert online and Physical course from DivCodeX (Private Limited) Bootcamp. <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_Shopify.png'}  class="img-fluid" alt="Divcodex_Bootcamps_Shopify"/>
        <h3>Shopify</h3>
        <p>Discover the secrets of successful Shopify stores with our specialized online and Physical course from DivCodeX (Private Limited). <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_Amazon.png'}  class="img-fluid" alt="Divcodex_Bootcamps_Amazon"/>
        <h3>Amazon</h3>
        <p>Navigate the complexities of Amazon selling with our targeted online and Physical courses from DivCodeX (Private Limited) Bootcamp. <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_E-Bay.png'}  class="img-fluid" alt="Divcodex_Bootcamps_E-Bay"/>
        <h3>E-Bay</h3>
        <p>Unlock the power of eBay selling with our specialized online course and Physical course from DivCodeX (Private Limited) Bootcamps. <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_Dropshipping.png'}  class="img-fluid" alt="Divcodex_Bootcamps_Dropshipping"/>
        <h3>Dropshipping</h3>
        <p>Learn the ins and outs of profitable dropshipping with our comprehensive online course and Physical course from DivCodeX (Private Limited). <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={process.env.PUBLIC_URL + '/Images/Divcodex_Bootcamps_TikTok_Shop_Course.JPEG'}  class="img-fluid" alt="Divcodex_Bootcamps_TikTok_Shop_Course"/>
        <h3>TikTok Shop Course</h3>
        <p>Maximize your TikTok shop's potential with our dedicated online course and Physical course from DivCodeX (Private Limited). <Link onClick={scrollToTop} className='alink' to="/DivCodeX_Bootcamp">ApplyNow</Link></p>
      </div>
    </div>
  </div>



</div>
      </div> 
         </div>
    </div>
</div> 
 
    </>
  )
}

export default Bootcamps