import React from 'react'
import "./CCTV_Carmra.css"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
import Cta from '../../components/compopages/Cta'
const CCTV_Carmra = () => {
  return (
    <>
         <Helmet>
        {/* SEO Title Tag */}
        <title>CCTV Camera Solutions | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) offers CCTV camera solutions for businesses and homes. We specialize in installation, maintenance, and monitoring of security systems to ensure your safety."
        />

        {/* Meta Keywords (use sparingly) */}
        <meta
          name="keywords"
          content="CCTV camera, security systems, video surveillance, camera installation, home security, business security, DivCodeX (Private Limited)"
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_CCTV_Camera" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="CCTV Camera Solutions | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="DivCodeX (Private Limited) provides CCTV camera solutions for homes and businesses. We specialize in security camera installation, maintenance, and monitoring."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_CCTV_Camera" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CCTV Camera Solutions | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) offers CCTV camera solutions, specializing in installation, maintenance, and monitoring for enhanced security in homes and businesses."
        />
      </Helmet>
       <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">CCTV Camera</h1>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container feature px-lg-0">
            <div className="row p-4 g-0 mx-lg-0">
                <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div className="p-lg-5 ps-lg-0">
                    
                        <h1 className=" mb-5">DivCodex (Private Limited) CCTV Camera</h1>
                        <p className="mb-4 pb-2">Looking for reliable CCTV cameras? DivCodex (Private Limited) offers top-of-the-line surveillance systems that keep your property secure. Whether you need to monitor your home or business, our cameras provide clear video and robust features to give you peace of mind.</p>
                        <div className="row g-4">
                            <div className="col-6">
                                <div className="d-flex align-items-center">
                                    <div className="btn-square bg-white rounded-circle witt2">
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-7.png'}  alt="Icon"/>
                                    </div>
                                    <div className="ms-4">
                                        <p className="text-primary mb-2">Trusted</p>
                                        <h5 className="mb-0">Security</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center">
                                    <div className="btn-square bg-white rounded-circle witt2" >
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-10.png'} alt="Icon"/>
                                    </div>
                                    <div className="ms-4">
                                        <p className="text-primary mb-2">Quality</p>
                                        <h5 className="mb-0">Services</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center">
                                    <div className="btn-square bg-white rounded-circle witt2">
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-3.png'} alt="Icon"/>
                                    </div>
                                    <div className="ms-4">
                                        <p className="text-primary mb-2">Smart</p>
                                        <h5 className="mb-0">Systems</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center">
                                    <div className="btn-square bg-white rounded-circle witt2" >
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-2.png'} alt="Icon"/>
                                    </div>
                                    <div className="ms-4">
                                        <p className="text-primary mb-2">24/7 Hours</p>
                                        <h5 className="mb-0">Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pe-lg-0 maini">
                    <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100 imgaa" src={process.env.PUBLIC_URL + '/Images/feature.jpg'}   alt="CCTV_Divcodex (Private Limited)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-4">
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                    <div className="h-100 bg-dark p-4 p-xl-5">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="btn-square rounded-circle roud_btn" >
                                <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-3.png'}  alt="Icon"/>
                            </div>
                            <h1 className="display-1 mb-0 bgcolor">01</h1>
                        </div>
                        <h5 className="text-white">Home Security</h5>
                        <hr className="w-25"/>
                        <span  className='text-white'>Invest in your safety with DivCodex (Private Limited) CCTV cameras. From small homes to large businesses, we have a security solution for you</span>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                    <div className="h-100 bg-dark p-4 p-xl-5">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="btn-square rounded-circle roud_btn" >
                                <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-4.png'} alt="Icon"/>
                            </div>
                            <h1 className="display-1 mb-0 bgcolor">02</h1>
                        </div>
                        <h5 className="text-white">Access Control</h5>
                        <hr className="w-25"/>
                        <span  className='text-white'>Monitor your property from anywhere using your smartphone or computer.</span>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div className="h-100 bg-dark p-4 p-xl-5">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="btn-square rounded-circle roud_btn" >
                                <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-2.png'}  alt="Icon"/>
                            </div>
                            <h1 className="display-1 mb-0 bgcolor">03</h1>
                        </div>
                        <h5 className="text-white">24/7 Support</h5>
                        <hr className="w-25"/>
                        <span className='text-white'>Take the first step toward enhanced security. Contact DivCodex (Private Limited) today to find the perfect CCTV camera for your needs. Don't wait—protect your property with DivCodex CCTV cameras.





</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container about px-lg-0">
            <div class="row p-4 g-0 mx-lg-0">
                <div class="col-lg-6 ps-lg-0 maini">
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100 imgaa" src={process.env.PUBLIC_URL + '/Images/aboutc.jpg'}  alt="Divcodex_(Private Limited)_aboutus"/>
                    </div>
                </div>
                <div class="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 pe-lg-0">
                        <h1 class="display-5 mb-4">CCTC Camera DivCodex (Private Limited)</h1>
                        <p class="mb-4 pb-2">Invest in your safety with DivCodex (Private Limited) CCTV cameras. From small homes to large businesses, we have a security solution for you. Our cameras are durable and designed to withstand various weather conditions, making them ideal for indoor and outdoor use.</p>
                        <div class="row g-4 mb-4 pb-3">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle roud_btn" >
                                        <img class="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-1.png'}  alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">24</h2>
                                        <p class="fw-medium text-primary mb-0">Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square bg-white rounded-circle roud_btn" >
                                        <img class="img-fluid" src={process.env.PUBLIC_URL + '/Images/icon/icon-5.png'}  alt="Icon"/>
                                    </div>
                                    <div class="ms-4">
                                        <h2 class="mb-1" data-toggle="counter-up">25</h2>
                                        <p class="fw-medium text-primary mb-0">Projects Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="/DivCodeX_Applynow" class="btn btn-primary rounded-pill py-3 px-5">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Cta/>
    
    </>
  )
}

export default CCTV_Carmra