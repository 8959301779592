import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Aboutus.css"
const Aboutus = () => {
  useEffect(() => {
    AOS.init({

    });
  }, []);
  return (
    <>
     <section id="about" className="container-xxl about pt-0">
        <div className="row">
          <div className=" col-lg-4 position-relative align-self-start order-lg-last order-first">
            <img src={process.env.PUBLIC_URL + '/Images/Headerimg/Aboutus.jpg'} className="img-fluid aboutimg" alt="DivCodeX About Us Images"/>
            {/* <Link to="#" className="glightbox play-btn"></Link> */}
          </div>
          <div className="col content order-last  order-lg-first">
            <h3>About Us</h3>
            <p className='text-just'>
           
            Welcome to <strong>DivCodeX (Private Limited),</strong> it is a prominent digital company that specializes in transforming concepts into powerful online identities.
With a fervor for innovation and an unwavering dedication to excellence, We are experts in <strong>Website Development,</strong><strong> Software Development </strong> , <strong>Digital Marketing, </strong> <strong> CCTV Cameras, </strong> <strong>Hardware and Networking,</strong> and <strong>SEO Optimization  </strong>solutions.
 Our team has a passion for innovation and an unrelenting commitment to perfection.As a result of the depth of expertise on our team, we are confident that every project will take on perfectly combines creativity, functionality, and user-centric design. At<strong> DivCodeX (Private Limited),</strong> we take pride in our abilities to optimize for search engine efficiency while pushing above and beyond to create results that advance organizations in the always changing digital landscape.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="content order-last order-lg-first">
            <ul>
              <li data-aos="zoom-in">
                <i className="bi bi-code-square"></i>
                <div>
                  <h5>Software Develpoment</h5>
                  <p>Our<strong> software development</strong> services of <strong>DivCodeX (Private Limited)</strong> are the highest standard of quality in every aspect.<strong>DivCodeX (Private Limited) </strong>team carefully plans, develops, and analyzes software solutions that fulfill exactly what you need. You may be sure that <strong>DivCodeX (Private Limited) </strong>has the expertise to handle your <strong>software development</strong> demands.</p>
                </div>
              </li>
              <li data-aos="zoom-in" >
                <i className="bi bi-file-earmark-code"></i>
                <div>
                  <h5>Web Development</h5>
                  <p> <strong>DivCodeX (Private Limited) </strong> experts in making awesome websites that look great, work well, and are easy for people to use. We use the newest technology and best design ideas to create websites that fit exactly what you need. When you choose <strong>DivCodeX (Private Limited),</strong> you can be sure your website will shine online, getting people interested and helping your business succeed.</p>
                </div>
              </li>
              <li data-aos="zoom-in">
                <i className="bi bi-search"></i>
                <div>
                  <h5>SEO Optimization</h5>
                  <p> <strong>DivCodeX (Private Limited),</strong> we know how crucial it is to be visible online, and that's where <strong>SEO Optimization</strong> comes in. Our SEO services are tailored to boost your website's ranking on search engines, bringing in more visitors naturally and <strong> boosting your online </strong> reach. With our focus on keyword research, content improvement, and technical SEO, we help your website reach its maximum potential and <strong> connect with the right audience</strong>. Choose<strong> DivCodeX (Private Limited) </strong>for SEO Optimization that helps your business thrive in today's competitive digital world.</p>
                </div>
              </li>
              <li data-aos="zoom-in">
                <i className="bi bi-phone"></i>
                <div>
                  <h5>Mobile Application Development</h5>
                  <p><strong>DivCodeX (Private Limited)</strong> experts in making mobile apps that are unique and easy for users to enjoy. We use the<strong> latest technology </strong>and the best ways of working to make sure your app works great, performs well, and gives users a great experience. From planning to launching, we're here to help you turn your app ideas into reality, so you can connect with your audience effectively in the growing world of mobile.</p>
                </div>
              </li>
              <li data-aos="zoom-in">
                <i className="bi bi-megaphone"></i>
                <div>
                  <h5>Digital Marketing</h5>
                  <p> <strong>DivCodeX (Private Limited)</strong> passionate about digital marketing and helping your business grow. We use smart strategies to make sure you're visible online and attracting customers. Our digital marketing services cover everything from <strong>SEO to social media,</strong> content creation, email campaigns, and beyond. With DivCodeX (Private Limited), you get customized marketing plans that match your business goals and show real results in today's busy online world.</p>
                </div>
              </li>
              <li data-aos="zoom-in">
                <i className="bi bi-camera-video"></i>
                <div>
                  <h5>CCTV Cameras</h5>
                  <p> <strong>DivCodeX (Private Limited) </strong>experts in security, offering top-notch <strong>CCTV camera solutions</strong> that fit your needs perfectly. With our know-how in <strong>CCTV cameras,</strong> we make sure your property stays safe and secure, giving you peace of mind. Whether it's for your business or home, our CCTV camera systems are equipped with the latest tech to provide clear surveillance footage and live monitoring. Count on <strong>DivCodeX (Private Limited)</strong> for trustworthy CCTV camera installations and complete security solutions.</p>
                </div>
              </li>
              <li data-aos="zoom-in"data-aos-delay="300">
                <i className="bi bi-hdd-rack"></i>
                <div>
                  <h5>Hardware & Networking</h5>
                  <p><strong>DivCodeX (Private Limited) </strong> pros in Hardware & Networking, offering complete solutions for your tech setup. Our team focuses on providing dependable hardware and strong networking services that match your business needs perfectly. Whether you need help setting up your network,<strong> fixing hardware problems,</strong> or installing scalable solutions, DivCodeX (Private Limited) has the skills to support your IT setup. Count on us to fine-tune your hardware and networking for smooth operation and top efficiency, helping your business succeed in today's digital world.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </section>
    
    </>
  )
}

export default Aboutus