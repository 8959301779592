import React from 'react'
import "./Website_develpoment.css"
import Cta from '../../components/compopages/Cta'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
const Website_develpoment = () => {
  return (

<>
<Helmet>
        {/* SEO Title */}
        <title>Professional Website Development Services | Divcodex (Private Limited)</title>

        {/* Meta Description */}
        <meta name="description" content="Divcodex (Private Limited) offers professional website development services, specializing in responsive design, e-commerce solutions, and web applications. Contact us to build your website." />

        {/* Meta Keywords */}
        <meta name="keywords" content="website development, web design, responsive design, e-commerce, web applications, Divcodex (Private Limited)" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Professional Website Development Services | Divcodex (Private Limited)" />
        <meta property="og:description" content="Divcodex (Private Limited) provides expert website development services, creating responsive websites, e-commerce platforms, and web applications." />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Website_Develpoment" /> {/* Replace with the actual URL of your Website Development page */}

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Professional Website Development Services | Divcodex (Private Limited)" />
        <meta name="twitter:description" content="Divcodex (Private Limited) specializes in website development, offering responsive design, e-commerce solutions, and custom web applications." />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Website_Develpoment" />
      </Helmet>
<div className="main-banner">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="owl-carousel owl-banner">
                <div className="item header-text">
                  <h2>Build <em>Website</em> <br/> Grow your <span>Business</span></h2>
                  <p>We specialize in innovative website development, creating custom digital solutions for businesses of all sizes. Our expert team ensures responsive design, seamless user experience, and optimized performance for search engines.</p>
                  <div className="down-buttons">
                    <div className="call-button">
                    <Link to="https://api.whatsapp.com/send?phone=+923210510033text=Hello%20TS%20Solutions%20Hub"><i className="fa fa-phone"></i>+92321-0510033</Link>
                    </div>
                  </div>
                </div>
             
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div id="about" className="about-us section mb-4">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 align-self-center">
          <div className="left-image">
            <img className='aboutimg' src={process.env.PUBLIC_URL + '/Images/about-left-image.png'}  alt="Divcodex_(Private Limited)_Website Develpoment"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="section-heading">
            <h2>DivCodeX (Private Limited): Crafting responsive, SEO-optimized websites that elevate your online presence.</h2>
            <p>DivCodeX (Private Limited) is your go-to place for creating awesome websites. We design sites that look great and are easy to use, customized to fit what your business needs. Our developers make sure your site works well on all devices and is set up to show up high in search engine results. From start to finish, we make the process smooth for you. Boost your online presence with DivCodeX—where creativity and technology come together.</p>
            <div className="row">
              <div className="col-lg-4">
                <div className="fact-item">
                  <div className="count-area-content">
                    <div className="icon">
                    <img src={process.env.PUBLIC_URL + '/Images/service-icon-01.png'} alt="Divcodex_Services"/>
                    </div>
                    <div className="count-digit">120</div>
                    <div className="count-title">E-Commerce website</div>
              
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fact-item">
                  <div className="count-area-content">
                    <div className="icon">
                      <img src={process.env.PUBLIC_URL + '/Images/service-icon-02.png'}   alt="ivcodex_Services1"/>
                    </div>
                    <div className="count-digit">140</div>
                    <div className="count-title">Business Websites</div>
                  
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fact-item">
                  <div className="count-area-content">
                    <div className="icon">
                      <img src={process.env.PUBLIC_URL + '/Images/service-icon-03.png'}  alt="ivcodex_Services2"/>
                    </div>
                    <div className="count-digit">200</div>
                    <div className="count-title">Satisfied Clients</div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<Cta/>

</>


  )
}

export default Website_develpoment