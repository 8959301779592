import React from 'react'
import "./Aboutus.css"
import { Helmet } from 'react-helmet';
import Aboutus2 from '../../components/compopages/Aboutus'
const Aboutus = () => {
  return (
    <>
        <Helmet>
        {/* SEO Title Tag */}
        <title>About Us - DivCodeX (Private Limited) | Leading IT and Software Company</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Learn more about DivCodeX, a leading IT and software company specializing in software development, IT consulting, and technology solutions. Discover our mission, vision, and values."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_About-Us" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="About Us - DivCodeX | Leading IT and Software Company" />
        <meta
          property="og:description"
          content="DivCodeX is a leading IT and software company with a commitment to innovation and customer satisfaction. Learn about our journey, team, and core values."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_About-Us" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - DivCodeX | Leading IT and Software Company" />
        <meta
          name="twitter:description"
          content="Find out about DivCodeX, a leading IT and software company. Learn about our team, mission, and the technology solutions we provide to our clients."
        />
      </Helmet>
      <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">About Us</h1>
                </div>
            </div>
        </div>
        <div className='mt-5'>
<Aboutus2/>
        </div>

    </>
  )
}

export default Aboutus