import React from 'react'
import "./Services.css";
import { Link } from 'react-router-dom';
const Services = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <>
      <div className="container-xxl">
        <div className="row g-3 ">
                <div className="col-lg-4 col-md-6" data-wow-delay="0.3s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center">
                            <i className="icons1 bi bi-code-square"></i>
                        <h4 className="mb-3">Software Development</h4>
                        <p className=" mb-3 text-just">At DivCodeX (Private Limited), our Software Development services epitomize excellence in every aspect of the process. DivCodeX (Private Limited) team <b className='color-blue'> Readmore</b></p>
                        <Link to="/DivCodeX_Software_Development" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item  d-flex flex-column align-items-center justify-content-center">
                            <i className="icons1 bi bi-file-earmark-code"></i>
                        <h4 className="mb-3">Website Development</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), our WebSite Development services are unparalleled in their dedication to crafting stunning, functional, and user-friendly <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_Website_Develpoment" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center ">
                            <i className="icons1 bi bi-search"></i>
                        <h4 className="mb-3">SEO Optimization</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we understand the critical importance of SEO Optimization in ensuring your online visibility and success <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_SEO_Optimization" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center text-center">
                            <i className=" icons1 bi bi-phone"></i>
                        <h4 className="mb-3">Mobile Application Development</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we excel in Mobile Application Development, crafting innovative and user-centric mobile apps that cater to <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_Mobile_Application_Development" onClick={scrollToTop} className='button-30'>More Details</Link>
                   
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center text-center">
                            <i className=" icons1 bi bi-megaphone"></i>
                        <h4 className="mb-3">Digital Marketing</h4>
                        <p className="mb-3 text-just">DivCodeX (Private Limited) takes pride in its expertise in Digital Marketing, employing strategic approaches to amplify your online presence <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_Digital_Marketing" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center text-center">
                  
                            <i className="icons1 bi bi-camera-video"></i>
                    
                        <h4 className="mb-3">CCTV Cameras</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we specialize in providing cutting-edge CCTV Camera solutions tailored to meet your security <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_CCTV_Camera" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item  d-flex flex-column align-items-center justify-content-center text-center">

                            <i className="icons1 bi bi-hdd-rack"></i>
                        <h4 className="mb-3">Hardware & Networking</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we are experts in Hardware & Networking, offering comprehensive solutions to meet your technology <b className='color-blue'> Readmore</b> </p>
                         <Link to="/DivCodeX_Hardware_Network" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item d-flex flex-column align-items-center justify-content-center text-center">
                            <i className="icons1 bi bi-youtube"></i>
                        <h4 className="mb-3">Ads Marketing</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we understand the power of effective Ads Marketing in driving business growth and expanding brand reach. <b className='color-blue'> Readmore</b></p>
                         <Link to="/DivCodeX_Ads_Marketing" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="service-item  d-flex flex-column align-items-center justify-content-center text-center">

                            <i className="icons1 bi bi-palette-fill"></i>
                        <h4 className="mb-3">Graphic Designing</h4>
                        <p className="mb-3 text-just">At DivCodeX (Private Limited), we recognize the pivotal role that graphic design plays in shaping brand identity and <b className='color-blue'> Readmore</b> </p>
                         <Link to="/DivCodeX_Graphic_Designing" onClick={scrollToTop} className='button-30'>More Details</Link>
                    </div>
                </div>
        
             
            </div>
        </div>

    
    </>
  )
}

export default Services