import React, { useEffect } from 'react';
import AOS from 'aos';
import "./SEO_Optimization.css"
import { Link } from 'react-router-dom'
import Cta from '../../components/compopages/Cta';
import { Helmet } from 'react-helmet';
const SEO_Optimization = () => {

  useEffect(() => {
    AOS.init({

    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  return (
    
    <>
            <Helmet>
        {/* SEO Title Tag */}
        <title>Professional SEO Services | Divcodex (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Divcodex (Private Limited) offers professional SEO services, including keyword research, on-page optimization, and backlink building. Contact us to boost your website's search engine rankings."
        />

        {/* Canonical URL to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_SEO_Optimization" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Professional SEO Services | Divcodex" />
        <meta
          property="og:description"
          content="Get expert SEO services from Divcodex. We specialize in keyword research, on-page optimization, and backlink building."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_SEO_Optimization" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Professional SEO Services | Divcodex" />
        <meta
          name="twitter:description"
          content="Divcodex (Private Limited) provides expert SEO services, including on-page optimization, keyword research, and backlink building."
        />
      </Helmet>
        <div class="container-xxl py-5 bg-cc hero-header mb-5">
                <div class="container my-5 py-5 px-lg-5">
                    <div class="row g-5 py-5">
                        <div class="col-lg-6 text-center text-lg-start">
                            <h1 class="text-white mb-4 animated zoomIn">Elevate Your Digital Presence with DivCodex (Private Limited): SEO Strategies for Success</h1>
                            <p class="text-white pb-3 animated zoomIn">In today's digital landscape, establishing a robust online presence is crucial for businesses to thrive. With search engines being a primary gateway for users to discover products and services, mastering Search Engine Optimization (SEO) is paramount. At DivCodex, we specialize in empowering businesses like yours to enhance their visibility and drive organic traffic. Here are some essential SEO strategies tailored to elevate your digital presence with DivCodex:</p>

                            <Link onClick={scrollToTop} to="/DivCodeX_Applynow" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</Link>
                        </div>
                        <div class="col-lg-6 text-center text-lg-start">
                            <img class="img-fluid" src={process.env.PUBLIC_URL + '/Images/hero.png'} alt="Divcodex_SEO_image"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
            <div class="container px-lg-5">
                <div class="row g-5">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="section-title position-relative mb-4 pb-2">
                            
                            <h2 class="mt-2">The best SEO solution with 5 years of experience</h2>
                        </div>
                        <p class="mb-4">Partner with DivCodeX (Private Limited) to unlock the full potential of your online presence and achieve sustainable growth in the digital landscape. Contact us today to embark on your journey to SEO success!</p>
                        <div class="row g-3 mb-3">
                            <div class="col-sm-6">
                                <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Keyword Research & Optimization</h6>
                                <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Content Creation & Optimization</h6>
                            </div>
                            <div class="col-sm-6">
                                <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Technical SEO  & Implementation</h6>
                                <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Link Building & Outreach</h6>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-sm-6">
                                <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Local SEO Optimization</h6>
                            </div>
                            <div class="col-sm-6">
                                <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Analytics & Performance Tracking</h6>
                              </div>
                        </div>
                   
                    </div>
                    <div class="col-lg-6">
                        <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src={process.env.PUBLIC_URL + '/Images/about.jpg'} alt='Divcodex (Private Limited)_SEO'/>
                    </div>
                </div>
            </div>
        </div>
        <Cta/>
    </>
  )
}

export default SEO_Optimization