import React from 'react'
import "./Services.css"
import { Helmet } from 'react-helmet';
import Services1 from '../../components/compopages/Services.jsx'
const Services = () => {
  
  return (
    <>
     <Helmet>
        {/* SEO Title Tag */}
        <title>IT and Software Services | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) offers a range of IT and software services, including software development, IT consulting, mobile app development, and network solutions. Contact us to learn more."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Services" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="IT and Software Services | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="Discover a wide range of IT and software services at DivCodeX (Private Limited). We specialize in software development, IT consulting, mobile app development, and hardware network solutions."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Services" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="IT and Software Services | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) provides a variety of IT and software services, including software development, IT consulting, and network solutions. Contact us for tailored IT solutions."
        />
      </Helmet>
          <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Services</h1>
                </div>
            </div>
        </div>
        <div className='mt-5'>
<Services1/>
        </div>
    
    </>
  )
}

export default Services