import React ,{ useState,useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import "./Applunow.css"
import { Helmet } from 'react-helmet';
const Applinow = () => {
  function myFunc() { 
    window.location.href = "https://www.divcodex.com"; 
} 
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_7gneq5m', 'template_1qc8il7', form.current, {
          publicKey: '5Pph3JzVIUrkvHz1z',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert('Thanks For Query Contact You Shortly'); 
            myFunc();
   
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
    const [countries, setCountries] = useState([]);
    
    useEffect(() => {
      fetchCountries();
    }, []);
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const countriesData = data.map(country => ({
          name: country.name.common,
          code: country.cca2
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
  return (
    <>
     <Helmet>
        {/* SEO Title Tag */}
        <title>Contact Us - DivCodeX (Private Limited) | Get in Touch with Us</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Contact DivCodeX for any inquiries or support. We offer IT and software services, including software development, IT consulting, and more. Get in touch with our team today."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_ContactUs" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Contact Us - DivCodeX | Get in Touch" />
        <meta
          property="og:description"
          content="Reach out to DivCodeX for IT and software services. We are here to answer your questions and help with your technology needs. Contact us for more information."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_ContactUs" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - DivCodeX | Get in Touch" />
        <meta
          name="twitter:description"
          content="Need to contact DivCodeX? We offer IT and software services. Reach out for inquiries, support, or more information about our solutions."
        />
      </Helmet>
    <div className="container register">
                <div className="row">
                    <div className="col-md-3 register-left">
                        {/* <img src="../../../public/DivCodeX_logo.png" alt=""/> */}
                        <h3 className='text-white'>Welcome DivCodeX (Private Limited)</h3>
                    </div>
                    <div className="col-md-9 register-right">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h3 className="register-heading">SEND QUERY FOR QUOTATION</h3>
                                <form className="row register-form"  ref={form} onSubmit={sendEmail}>
                                    <div className="col-md-6 ">
                                        <div className="form-group mb-2">
                                        <label  class="form-label">First Name</label>
        <input name="customer_name" type="text" class="form-control"  required placeholder="Enter your first name"/>                                        </div>
                                        <div className="form-group mb-2">
                                        <label for="email" class="form-label">Email Address</label>
        <input type="email" class="form-control" id="email" name="Email" required placeholder="Enter your email address"/>
                                         </div>
                                        <div className="form-group mb-2">
                                        <label for="country" class="form-label">Country</label>
        <select className="form-select" name='country' required id="country">
        <option value="">Select Country</option>
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>                                        </div>
                                        <div className="form-group mb-2">
                                        <label for="project" class="form-label">Services</label>
        <select  class="form-select" required name='project_name'>
        <option value="">Select Services</option>
          <option value="Software Development">Software Development</option>
          <option value="Website Development">Website Development</option>
          <option value="SEO Optimization">SEO Optimization</option>
          <option value="Mobile Application Development">Mobile Application Development</option>
          <option value="Digital Marketing">Digital Marketing</option>
          <option value="CCTV Cameras">CCTV Cameras</option>
          <option value="Hardware & Networking">Hardware & Networking</option>
          <option value="Ads Marketing">Ads Marketing</option>
          <option value="Graphic Designing">Graphic Designing</option>
        </select>
                                        </div>
                                        
                                      
                                    
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group mb-2">
                                    <label for="lastName" class="form-label">Last Name</label>
        <input type="text" class="form-control" id="lastName" name="father_name" required placeholder="Enter your last name"/>
                                        </div>
                                        <div className="form-group mb-2">
                                        <label for="project" class="form-label">Phone No</label>
        <input type="number" class="form-control" name='mobile_number' required  placeholder="Enter your number"/>

                                        </div>
                                        <div className="form-group mb-2">
                                        <label for="city" class="form-label">City</label>
        <input type="text" name="city" class="form-control" required id="project" placeholder="Enter your City"/>
                                        </div>
                                        <div className="form-group mb-2">
                                        <label for="description" class="form-label">Description</label>
        <textarea class="form-control" required id="description" rows="3" name='message' placeholder="Enter project description"></textarea>

                                        </div>
                                        <input type="submit" className="btnRegister"  value="Submit"/>
                                   
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

</div>
    </>
  )
}

export default Applinow