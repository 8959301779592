import React from 'react'
import { Link } from 'react-router-dom'
import "./Footer.css"
const Footer = () => {
  return (
    <>
     <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
     <div className="container gx-5">
            <div className="row gx-5">
                <div className="col-lg-4 col-md-6 footer-about">
                    <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                        <Link to="index.html" className="navbar-brand">
                             <img className='logo112' src={process.env.PUBLIC_URL + '/Images/DivCodeX_logo.png'} alt="Pakistan Top Software Company, Top Ten All kind of Software, Websites, Web Application, SEO , digital marketing"  />
                        </Link>
                        <p className="mt-1 text-just">DivCodeX, a leading force in the digital sphere, devoted to translating ideas into impactful online presences. With a fervor for innovation and an unwavering dedication to excellence, we specialize in Website Development, Software Development, Digital Marketing, CCTV Cameras, Hardware & Networking, and SEO Optimization solutions.</p>
                    </div>
                </div>
                <div className="col-lg-8 col-md-6">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Get In Touch</h3>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-geo-alt text-primary me-2"></i>
                                <p className="mb-0">Office#87 Al Rehman Garden Phase 2 Gate#1  Lahore.</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-envelope-open text-primary me-2"></i>
                                <p className="mb-0">info@divcodex.com</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-primary me-2"></i>
                                <p className="mb-0">+92-321-0410033</p>
                              
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-primary me-2"></i>
             
                                <p className="mb-0">+92-321-0510033</p>
                            </div>
                            <div className="d-flex mt-4">
         
                                <Link className=" textdecnon btn btn-primary btn-square me-1" to="https://www.facebook.com/profile.php?id=61557357430259" target='_blank'><i className="fab fa-facebook-f fw-normal"></i></Link>
                                <Link className=" textdecnon btn btn-primary btn-square me-1" to="https://www.linkedin.com/in/divcodex/"><i className="fab fa-linkedin-in fw-normal"></i></Link>
                                <Link className=" textdecnon btn btn-primary btn-square me-1" to="https://www.instagram.com/divcodex/"><i className="fab fa-instagram fw-normal"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Quick Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <Link className=" textdecnon text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_About-Us"><i className="bi bi-arrow-right text-primary me-2"></i>About</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Services"><i className="bi bi-arrow-right text-primary me-2"></i>Services</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Products"><i className="bi bi-arrow-right text-primary me-2"></i>Products</Link>
                                {/* <Link className=" textdecnon text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Portfolio</Link> */}
                              
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Bootcamps"><i className="bi bi-arrow-right text-primary me-2"></i>Bootcamps</Link>
  {/* <Link className=" textdecnon text-light mb-2" to="/TS_Solutions_Hub_Team"><i className="bi bi-arrow-right text-primary me-2"></i>Team</Link> */}
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Careers"><i className="bi bi-arrow-right text-primary me-2"></i>Careers</Link>
                                <Link className=" textdecnon text-light" to="/DivCodeX_ContactUs"><i className="bi bi-arrow-right text-primary me-2"></i>Contact US</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Popular Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Web Development</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Software Develpoment</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>SEO Optimization</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Mobile Apps Development</Link>
                                <Link className=" textdecnon text-light mb-2" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Digital Marketing</Link>
                                <Link className=" textdecnon text-light" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>CCTV Cameras</Link>
                                <Link className=" textdecnon text-light" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Hardware & Networking</Link>
                                <Link className=" textdecnon text-light" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Ecomerace Website</Link>
                                <Link className=" textdecnon text-light" to="/DivCodeX_Applynow"><i className="bi bi-arrow-right text-primary me-2"></i>Bussnuss Management</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
 
    <div className="container-fluid text-white foobg">
    <div className="container">
            <div className="row ">
                    <div className="align-items-center text-center " >
                        <p className='ssk'>&copy; <Link className=" textdecnon text-white " to="https://divcodex.com">DivCodeX.com</Link>. All Rights Reserved. 
						Designed by <Link className=" textdecnon text-white " to="https://divcodex.com">DivCodeX Software Development Company</Link>
                       </p></div>
                       </div>
                </div>
    </div>
    </div>
    </>
  )
}

export default Footer