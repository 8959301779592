import React from 'react'
import "./Team.css"
const Team = () => {
  return (
    <>
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="row g-5">
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div><div className="col-lg-3 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded overflow-hidden">
                        <div className="team-img position-relative overflow-hidden">
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/team-1.jpg'}  alt=""/>
                            <div className="team-social">
                            <div className='team11'>
                           <h4 className="text-white">Full Name</h4>
                            <p className="text-uppercase text-white m-0">Designation</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default Team