import React from 'react'
import { Link } from 'react-router-dom'
import "./Social.css"
const Social = () => {
  return (
    <>
    <div className="icon-bar">
<Link  to="https://api.whatsapp.com/send?phone=+923210510033text=Hello%20TS%20Solutions%20Hub"  className="whatsapp a1" target="_blank"><i className="fa fa-whatsapp"></i></Link> 
  <Link to="https://www.facebook.com/profile.php?id=61557357430259" target="_blank" className="facebook a1"><i className="fa fa-facebook"></i></Link>
      {/* <Link to="#" className="twitter a1" target="_blank"><i className="fa fa-twitter"></i></Link>  */}
<Link to="https://www.instagram.com/divcodex/" className="Instragram a1" target="_blank"><i className="fa fa-instagram"></i></Link>  
  <Link to="https://www.linkedin.com/in/divcodex/" className="linkedin a1" target="_blank"><i className="fa fa-linkedin"></i></Link>
  {/* <Link to="#" className="youtube a1" target="_blank"><i className="bi bi-youtube"></i></Link>  */}
</div>
    </>
  )
}

export default Social