import React from 'react'
import "./Products.css"
import { Link } from 'react-router-dom'
const Products = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <>
     <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="row g-5 p-2">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/divcodexlabmanagmentsyatem.jpg'} alt="divcodexlabmanagmentsyatem"/>
                        </div>
                        <div className="p-4">
            
                            <h4 className="mb-3">Lab Managment System</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/DivCodeX-custom-software.png'} alt="DivCodeX-custom-software"/>
                        </div>
                        <div className="p-4">

                            <h4 className="mb-3">Custom Web Application Solutions</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/DivCodex-Responsive-Web-Design.jpg'} alt="DivCodex-Responsive-Web-Design"/>
                        </div>
                        <div className="p-4">
                            <h4 className="mb-3">Custom Website Solutions</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-5 p-2">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/DivcodeX_School_Management_System.png'} alt="DivcodeX_School_Management_System "/>
                        </div>
                        <div className="p-4">
            
                            <h4 className="mb-3">School Managment System</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/DivcodeX_Point_of_Sale.png'} alt="DivcodeX_Point_of_Sale"/>
                        </div>
                        <div className="p-4">

                            <h4 className="mb-3">Points of Sales System</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                        <div className="blog-img position-relative overflow-hidden">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/Images/Products/DivcodeX_Ecommerce_Websites.png'} alt="DivcodeX_Ecommerce_Websites"/>
                        </div>
                        <div className="p-4">
                            <h4 className="mb-3">Ecommerce Websites</h4>
                            {/* <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p> */}
                            <Link className="text-uppercase text-decoration-none" to="/DivCodeX_ContactUs" onClick={scrollToTop}>Get Demo <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Products