import React, { useEffect } from 'react';
import "./Digital_Marketing.css"
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import Cta from '../../components/compopages/Cta'
const Digital_Marketing = () => {
    useEffect(() => {
        AOS.init({
    
        });
      }, []);
    return (
   <>
    <Helmet>
        {/* SEO Title Tag */}
        <title>Digital Marketing Services | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) offers comprehensive digital marketing services, including SEO, social media marketing, content marketing, and PPC campaigns. Boost your online presence with our expert team."
        />

        {/* Meta Keywords (use sparingly) */}
        <meta
          name="keywords"
          content="digital marketing, SEO, social media marketing, content marketing, PPC, online advertising, DivCodeX (Private Limited)"
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Digital_Marketing" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Digital Marketing Services | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="Explore DivCodeX's digital marketing services, including SEO, social media marketing, content marketing, and PPC campaigns. Contact us to improve your online presence."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Digital_Marketing" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Digital Marketing Services | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) offers digital marketing services, including SEO, social media marketing, content marketing, and PPC campaigns. Contact us for effective online marketing solutions."
        />
      </Helmet>
<section id="hero22">

<div className="container">
  <div className="row d-flex align-items-center">
  <div className=" col-lg-6 py-5 py-lg-0 order-2 order-lg-1" data-aos="fade-right">
    <h1>Divcodex (Private Limited): Your Partner in Digital Marketing Success</h1>
    <p className='text-white'>At Divcodex (Private Limited), we understand that the digital landscape is constantly evolving. That's why we're committed to staying ahead of the curve, providing you with innovative and effective digital marketing solutions that drive results. Whether you're a small business or a large corporation, our team of experts is dedicated to helping you achieve your marketing goals.</p>
    {/* <a href="#about" className="btn-get-started scrollto">Get Started</a> */}
  </div>
  <div className="col-lg-6 order-1 order-lg-2 hero22-img" data-aos="fade-left">
    <img  src={process.env.PUBLIC_URL + '/Images/hero-imgdit.png'} className="img-fluid" alt="Divcodex_(Private Limited)_Digital_Marketing"/>
  </div>
</div>
</div>
</section>
<section id="about" className="about section-bg">
      <div className="container">

        <div className="row gy-4">
          <div className="image col-xl-5"></div>
          <div className="col-xl-7">
            <div  ame="content d-flex flex-column justify-content-center ps-0 ps-xl-4">
              <h3 data-aos="fade-in" data-aos-delay="100">What We Offer</h3>
              
              <div className="row gy-4 mt-3">
                <div className="col-md-6 icon-box" data-aos="fade-up">
                  <i className="bx bx-receipt"></i>
                  <h4>Search Engine Optimization (SEO)</h4>
                  <p>Stand out in search results and attract organic traffic with our comprehensive SEO strategies. At Divcodex (Private Limited), we use the latest techniques and tools to boost your website's visibility, ensuring you reach the right audience at the right time.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-cube-alt"></i>
                  <h4>Pay-Per-Click Advertising (PPC)</h4>
                  <p>Maximize your return on investment with targeted PPC campaigns. Our team at Divcodex (Private Limited) creates and manages campaigns across various platforms, including Google Ads and social media, to deliver measurable results.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-images"></i>
                  <h4>Social Media Marketing</h4>
                  <p>Engage with your audience and build your brand on social media. We create customized social media strategies that reflect your brand's voice and connect with your target demographic. From content creation to community management, Divcodex has you covered.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-shield"></i>
                  <h4>Content Marketing</h4>
                  <p>Quality content is at the heart of every successful digital marketing strategy. At Divcodex (Private Limited), we produce engaging and relevant content that resonates with your audience, helping you establish thought leadership and drive customer engagement.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-images"></i>
                  <h4>Email Marketing</h4>
                  <p>Stay top-of-mind with your customers through personalized email campaigns. We design, write, and manage email marketing strategies that convert leads into customers and keep your existing clients coming back for more.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-images"></i>
                  <h4>Whats App Marketing</h4>
                  <p>Stay top-of-mind with your customers through personalized Whats app campaigns. We design, write, and manage Whats app marketing strategies that convert leads into customers and keep your existing clients coming back for more.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    {/* <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title">
          <h2 data-aos="fade-in">Services</h2>
          <p data-aos="fade-in">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div className="row">
          <div className="col-md-6 d-flex" data-aos="fade-right">
            <div className="card">
              <div className="card-img">
                <img src={process.env.PUBLIC_URL + '/Images/Digital/services-1.jpg'}  alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title">Temporibus laudantium</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex" data-aos="fade-left">
            <div className="card">
              <div className="card-img">
                <img src={process.env.PUBLIC_URL + '/Images/Digital/services-2.jpg'}  alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title">Aperiores voluptates</h5>
                <p className="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo</p>
              </div>
            </div>

          </div>
          <div className="col-md-6 d-flex" data-aos="fade-right">
            <div className="card">
              <div className="card-img">
                <img src={process.env.PUBLIC_URL + '/Images/Digital/services-3.jpg'}  alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title">Veritatis natus nisi</h5>
                <p className="card-text">Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex" data-aos="fade-left">
            <div className="card">
              <div className="card-img">
                <img src={process.env.PUBLIC_URL + '/Images/Digital/services-4.jpg'}  alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title">Aliquam veritatis</h5>
                <p className="card-text">Nostrum eum sed et autem dolorum perspiciatis. Magni porro quisquam laudantium voluptatem. In molestiae earum ab sit esse voluptatem. Eos ipsam cumque ipsum officiis qui nihil aut incidunt aut</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section> */}
    <Cta/>
   </>
  )
}

export default Digital_Marketing