import React from 'react'
import "./Contactus.css"
import Contactus1 from"../../components/compopages/Contactus"
const Contactus = () => {
  return (
    <>
          <div className="container-fluid bg-dark bg-header ">
            <div className="row py-2">
                <div className="col-12  text-center">
                    <h1 className="display-5 text-white">Contact Us</h1>
                </div>
            </div>
        </div>
        <div className='mt-5'>
<Contactus1/>
        </div>
    </>
  )
}

export default Contactus