import React from 'react'
import { Routes, Route} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import Navbar from "./components/Navbar/Navbar.jsx"
import Whatsapp from "./components/Whatsapp/Whatsapp.jsx"
import Home from "./page/Home/Home.jsx"
import Footer from "./components/Footer/Footer.jsx"
import Social from "./components/Socialicons/Social.jsx"
import Aboutus from './page/Aboutus/Aboutus.jsx';
import Services from './page/Services/Services.jsx';
import Products from './page/Products/Products.jsx';
import Team1 from './page/Team/Team.jsx';
import Conatct1 from './page/Contactus/Contactus.jsx';
import Bootcamps1 from './page/Bootscamps/Bootcamps.jsx';
import Careers1 from './page/Careers/Careers.jsx';
import SoftwareDevelopment from './page/Servicespages/Software_Development.jsx';
import Applynow from './page/Applynow/Applinow.jsx';
import Bootcamps from './page/Applynow/Bootcamps.jsx';
import SEOOptimization from "./page/Servicespages/SEO_Optimization.jsx";
import CCTVCarmra from './page/Servicespages/CCTV_Carmra.jsx';
import Mobile from './page/Servicespages/Mobile_App.jsx';
import Websitedevelpoment from './page/Servicespages/Website_develpoment.jsx';
import DigitalMarketing from './page/Servicespages/Digital_Marketing.jsx';
import HardwareNetwork from './page/Servicespages/Hardware_Network.jsx';
import AdsMarketing from './page/Servicespages/Ads_Marketing.jsx';
import GraphicDesigning from './page/Servicespages/Graphic_Designing.jsx';
const App = () => {
  return (
  <>
  <Navbar/>
  <Routes> 
        <Route path="/" element={<Home />}/>
        <Route path="/DivCodeX_About-Us" element={<Aboutus />}/>
        <Route path="/DivCodeX_Services" element={<Services />}/>
        <Route path="/DivCodeX_Products" element={<Products />}/>
        <Route path="/DivCodeX_Team" element={<Team1 />}/>
        <Route path="/DivCodeX_ContactUs" element={<Conatct1 />}/>
        <Route path="/DivCodeX_Bootcamps" element={<Bootcamps1 />}/>
        <Route path="/DivCodeX_Careers" element={<Careers1 />}/>
        <Route path="/DivCodeX_Software_Development" element={<SoftwareDevelopment/>}/>
        <Route path="/DivCodeX_Applynow" element={<Applynow/>}/>
        <Route path="/DivCodeX_Bootcamp" element={<Bootcamps/>}/>
        <Route path="/DivCodeX_SEO_Optimization" element={<SEOOptimization/>}/>
        <Route path="/DivCodeX_CCTV_Camera" element={<CCTVCarmra/>}/>
        <Route path="/DivCodeX_Mobile_Application_Development" element={<Mobile/>}/>
        <Route path="/DivCodeX_Website_Develpoment" element={<Websitedevelpoment/>}/>
         <Route path="/DivCodeX_Digital_Marketing" element={<DigitalMarketing/>}/>
         <Route path="/DivCodeX_Hardware_Network" element={<HardwareNetwork/>}/>
         <Route path="/DivCodeX_Ads_Marketing" element={<AdsMarketing/>}/>
         <Route path="/DivCodeX_Graphic_Designing" element={<GraphicDesigning/>}/>


        <Route path="*" element={<Home/>} /> 
      </Routes>
      <Whatsapp/>
      <Social/>
  <Footer/>
  </>
  )
}

export default App