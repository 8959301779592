import React from 'react'
import "./CEO.css";
import { Link } from 'react-router-dom';
const CEO = () => {
  return (
    <>


    <div className="container-xxl "  data-aos="zoom-in-up">
    <h3>Management Message</h3>
    <div  className="row">
 
          <div  className=" col-lg-5 mt-4  position-relative align-self-start order-lg-last order-first">
          <div className="row">
            <div  className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/Chief_Executive_Officer_Muhammad_Shahroz-removebg-preview.png'} alt=""/>
                        {/* <div className="team-social">
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></Link>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-primary">Muhammad Shahroz </h4>
                        <p className=" m-0">Chief Executive Officer</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <div className="team-item bg-light rounded overflow-hidden">
                    <div className="team-img position-relative overflow-hidden">
                        <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/Images/Team/Managing_Director_Talha Mehmood.png'}  alt=""/>
                        {/* <div className="team-social">
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></Link>
                        </div> */}
                    </div>
                    <div className="text-center py-4">
                        <h4 className="text-primary">Talha Mehmood</h4>
                        <p className=" m-0">Managing Director</p>
                    </div>
                </div>
            </div>
        </div>
          </div>
          <div className="col content order-last  order-lg-first">
            <p className='text-just mt-3 '>
Welcome to <strong>DivCodeX (Private Limited)</strong>, where we're dedicated to providing top-quality <strong> Software Solutions </strong> tailored specifically to your needs. As CEO,s I'm excited to lead our DivCodeX (Private Limited) Team in delivering outstanding services that exceed your expectations. Our commitment to excellence is unwavering, and we stay ahead of the curve by utilizing the <strong> Develpoment in Latest Technologies</strong> and industry best practices. <br />

<strong>At DivCodeX (Private Limited) </strong>, we value transparency, trust, and reliability, ensuring that <strong>Software Development</strong>, <strong>Web Application Develpoment</strong>, <strong>Website Development</strong>, <strong>Mobile Application Development</strong>, <strong>SEO Optimization</strong>,  <strong>Digital Marketing</strong>,  <strong>CCTV Cameras</strong>,  <strong>Hardware & Networking</strong>, <strong>Ads Marketing</strong> and <strong>Graphic Designing</strong>,  we undertake is approached with integrity. When you partner with us, you're not just getting a service provider – you're gaining a trusted ally in your journey towards success.<br/>

Whether you're in need of a <strong>Website Development</strong> , a <strong> Mobile Application </strong>, or <strong> enterprise-level software,</strong> DivCodeX (Private Limited) has the expertise and dedication to bring your vision to life. We're here to empower your business and help you thrive in the digital landscape.<br/>

Join us on this exciting journey of innovation and growth. Together, we'll navigate the complexities of the online world and unlock new opportunities for your success. Thank you for choosing DivCodeX (Private Limited) as your trusted partner. Let's make magic happen!</p>
          </div>
        </div>
</div>
</>
  )
}

export default CEO