import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Mobile_App.css"
import Cta from '../../components/compopages/Cta';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Mobile_App = () => {
    useEffect(() => {
        AOS.init({
    
        });
      }, []);
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <>
          <Helmet>
        {/* SEO Title Tag */}
        <title>Professional Mobile App Development | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) provides expert mobile application development services, specializing in iOS and Android apps. Contact us for custom mobile app solutions."
        />

        {/* Canonical URL to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Mobile_Application_Development" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Professional Mobile App Development | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="DivCodeX (Private Limited) offers expert mobile app development services. We create high-quality iOS and Android apps, custom-built to meet your business needs."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Mobile_Application_Development" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Professional Mobile App Development | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) specializes in mobile application development for iOS and Android platforms. Contact us for custom mobile app solutions."
        />
      </Helmet>
<div class="container-xxl position-relative p-0" id="home">
<div class="container-xxl backcolor hero-header">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-8 text-center text-lg-start">
                            <h1 class="text-white mb-4 animated slideInDown">The Future of Mobile App Development: What's Next?</h1>
                            <p class="text-white pb-3 animated slideInDown">Mobile app development has transformed the way we live, work, and play. From staying connected with friends to managing finances, mobile apps have become essential tools in our daily lives. As technology evolves, what does the future hold for mobile app development? Let's explore the latest trends and how they're shaping the industry.</p>
                      
                        </div>
                        <div class="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                            <div class="owl-carousel screenshot-carousel">
                                <img class="img-fluid" src={process.env.PUBLIC_URL + '/Images/screenshot-1.png'} alt="Divcodex_mobile_application"/>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
<div class="container-xxl py-5" id="about">
            <div class="container py-5 px-lg-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="mb-4">The Impact of Mobile Apps on Business</h1>
                        <p class="mb-4">Mobile apps are transforming the business landscape, offering new ways to connect with customers and streamline operations. Companies are leveraging mobile apps for:</p>
                        <div class="row g-4 mb-4">
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                <div class="d-flex">
                                    <i class="fa fa-cogs fa-2x  flex-shrink-0 mt-1"></i>
                                    <div class="ms-3">
                                        <h2 class="mb-0" data-toggle="counter-up">98</h2>
                                        <p class="mb-0">Active Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                <div class="d-flex">
                                    <i class="fa fa-comments fa-2x  flex-shrink-0 mt-1"></i>
                                    <div class="ms-3">
                                        <h2 class="mb-0" data-toggle="counter-up">78</h2>
                                        <p class=" mb-0">Happly Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="/DivCodeX_Applynow" onClick={scrollToTop} class="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Contact Us</Link>
                    </div>
                    <div class="col-lg-6">
                        <img class="img-fluid wow fadeInUp" data-wow-delay="0.5s" src={process.env.PUBLIC_URL + '/Images/aboutMo.png'} alt='Divcodez_mobileapplicationdevelopment'/>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-xxl py-5" id="feature">
            <div class="container py-5 px-lg-5">
                <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
         
                    <h1 class="mb-5">Mobile App Development</h1>
                    <p>At DivCodeX (Private Limited), our mobile app development process includes a comprehensive set of features to ensure you receive a high-quality product. Here's what we offer:</p>
                </div>
                <div class="row g-4">
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4 ss" >
                                <i class="fa fa-eye text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Custom Design</h5>
                            <p class="m-0"> We create unique designs tailored to your brand and audience, ensuring your app stands out.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Cross-Platform Compatibility</h5>
                            <p class="m-0">Our apps work seamlessly on both iOS and Android devices, providing a consistent experience for all users.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-edit text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">User-Friendly Interface</h5>
                            <p class="m-0">We focus on intuitive design to make your app easy to navigate, with clear menus, buttons, and interactions.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Performance Optimization</h5>
                            <p class="m-0"> Our apps are built for speed and efficiency, reducing load times and ensuring smooth operation.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4 ss" >
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Scalability</h5>
                            <p class="m-0">As your business grows, our apps can adapt. We design with future scalability in mind, allowing for easy updates and new features.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Backend Integration</h5>
                            <p class="m-0">We can connect your app to databases, APIs, or other backend systems, enabling robust functionality and data management.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-shield  text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Security</h5>
                            <p class="m-0">We prioritize security to protect your users' data, incorporating encryption, secure authentication, and compliance with industry standards.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Push Notifications</h5>
                            <p class="m-0">Keep your users engaged with targeted notifications that drive interaction and retention.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Analytics and Reporting</h5>
                            <p class="m-0">Gain insights into user behavior with integrated analytics tools, helping you make informed decisions.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="feature-item bg-light rounded p-4">
                            <div class="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4 ss">
                                <i class="fa fa-cloud text-white fs-4"></i>
                            </div>
                            <h5 class="mb-3">Support and Maintenance</h5>
                            <p class="m-0">Our relationship doesn't end at launch. We offer ongoing support, maintenance, and updates to ensure your app remains at its best.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Cta/>
    </>
  )
}

export default Mobile_App