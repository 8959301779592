import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Hardware_Network.css"
import Cta from '../../components/compopages/Cta';
import { Helmet } from 'react-helmet';
const Hardware_Network = () => {
    useEffect(() => {
        AOS.init({
    
        });
      }, []);
  return (
    <>
      <Helmet>
        {/* SEO Title Tag */}
        <title>Hardware and Network Solutions | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) provides comprehensive hardware and network solutions, including network design, installation, and support. Contact us for reliable network infrastructure."
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Hardware_Network" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Hardware and Network Solutions | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="DivCodeX (Private Limited) offers reliable hardware and network solutions, from network design and installation to ongoing support and maintenance."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Hardware_Network" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hardware and Network Solutions | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="DivCodeX (Private Limited) specializes in hardware and network solutions. We offer network design, installation, and ongoing support. Contact us to ensure reliable network infrastructure."
        />
      </Helmet>
      <section id="hero11">
    <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner" role="listbox">
        <div className="carousel-item active bg1" >
          <div className="carousel-container">
            <div className="carousel-content animate__animated animate__fadeInUp">
              <h2><span>Hardware</span> and <span>Networking</span> </h2>
              <p>Hardware comprises the physical components of a computer system, such as the CPU, memory, storage, and peripherals, which together execute software tasks. It includes everything from internal components like motherboards to external devices like keyboards and monitors.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="services1" className="services1 text-center">
  <h2 className='mb-2'>Computer Hardware</h2>
      <p>Computer hardware refers to the physical components that make up a computer system. Here's a breakdown of key hardware elements:</p>
      <div className="container">
      
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Hardware Solution</h4>
              <p className="description">Divcodex (Private Limited): Our company's main services focus on providing hardware and software solutions to comprehensively meet your needs.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Hardware Purchase Assistance</h4>
              <p className="description">Online hardware purchase assistance offers real-time guidance and recommendations for buying hardware components or devices through digital platforms..</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Hardware Troubleshooting Services</h4>
              <p className="description">Hardware Troubleshooting Services help diagnose, fix, and resolve issues with hardware components to ensure optimal performance and functionality.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Wireless Networking Maintenance Contracts</h4>
              <p className="description">Wireless Networking Maintenance Contracts provide ongoing support, updates, and repair services to maintain the reliability and performance of wireless network infrastructure.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Wireless Troubleshooting Maintenance</h4>
              <p className="description">Wireless Troubleshooting Maintenance involves diagnosing and resolving issues with wireless networks to ensure stable connectivity and performance.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="fa fa-desktop"></i></div>
              <h4 className="title">Optical Drives, Sound Cards, Network Cards</h4>
              <p className="description">Optical Drives, Sound Cards, and Network Cards are hardware components in a computer system, used for reading/writing discs, processing audio, and managing network connections, respectively.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="services1" className="services1 text-center">
  <h2 className='mb-2'>Networking</h2>
      <p>Networking involves connecting computers and devices to share information and resources. Here are some foundational concepts:</p>
      <div className="container">
      
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Hardware Solution</h4>
              <p className="description">Divcodex (Private Limited): Our company's main services focus on providing hardware and software solutions to comprehensively meet your needs.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Local Area Network (LAN)</h4>
              <p className="description">A network covering a small geographic area, like a home or office.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Wide Area Network (WAN)</h4>
              <p className="description">A network that spans large distances, often connecting multiple LANs.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Router</h4>
              <p className="description">A device that directs traffic between networks or within a network.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Switch</h4>
              <p className="description">A device that connects multiple devices within a LAN and directs data to the correct destination.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Wireless Access Point (WAP)</h4>
              <p className="description">Provides wireless connectivity to a network.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Modem</h4>
              <p className="description"> Converts data from analog to digital, allowing communication with an Internet Service Provider (ISP).</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="services1" className="services1 text-center">
  <h2 className='mb-2'>Advanced Networking Concepts</h2>
      <p>For more in-depth networking topics, consider the following:</p>
      <div className="container">
      
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">TCP/IP Model:</h4>
              <p className="description">The fundamental framework for internet communication, with layers like Network, Transport, and Application.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Virtual Private Network (VPN):</h4>
              <p className="description">A method to create a secure connection over a public network.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Firewall</h4>
              <p className="description">Security software or hardware that controls incoming and outgoing network traffic based on rules.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Network Security</h4>
              <p className="description">Practices to protect data and resources, including encryption, secure protocols (like HTTPS), and intrusion detection systems.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Cloud Computing</h4>
              <p className="description">Using remote servers to store, manage, and process data instead of local hardware.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="fa fa-sitemap"></i></div>
              <h4 className="title">Internet of Things (IoT)</h4>
              <p className="description">Connecting everyday devices to the internet for data exchange and automation.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Cta/>
    </>
  )
}

export default Hardware_Network