import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Hardware_Network.css"
import { Helmet } from 'react-helmet';
import Cta from '../../components/compopages/Cta';

const Graphic_Designing = () => {
    useEffect(() => {
        AOS.init({
    
        });
      }, []);
  return (
    <>
       <Helmet>
        {/* SEO Title Tag */}
        <title>Graphic Designing Services | DivCodeX (Private Limited)</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="DivCodeX (Private Limited) offers professional graphic designing services, including logo design, branding, marketing materials, and custom graphics. Contact us for creative design solutions."
        />

        {/* Meta Keywords (use sparingly) */}
        <meta
          name="keywords"
          content="graphic designing, logo design, branding, marketing materials, custom graphics, DivCodeX (Private Limited)"
        />

        {/* Canonical Link to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="https://www.divcodex.com/DivCodeX_Graphic_Designing" />

        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Graphic Designing Services | DivCodeX (Private Limited)" />
        <meta
          property="og:description"
          content="DivCodeX (Private Limited) offers professional graphic designing services, specializing in logo design, branding, marketing materials, and custom graphics. Get in touch for creative design solutions."
        />
        <meta property="og:url" content="https://www.divcodex.com/DivCodeX_Graphic_Designing" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Graphic Designing Services | DivCodeX (Private Limited)" />
        <meta
          name="twitter:description"
          content="Discover professional graphic designing services at DivCodeX (Private Limited) We specialize in logo design, branding, and custom graphics. Contact us for your design needs."
        />
      </Helmet>
          <section id="hero11">
    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active bg2" >
          <div class="carousel-container">
            <div class="carousel-content animate__animated animate__fadeInUp">
              <h2>Divcodex (Private Limited) Where Creativity Meets Innovation</h2>
              <p>Divcodex (Private Limited) is a forward-thinking graphic design studio dedicated to transforming ideas into visually stunning realities. Our mission is to deliver cutting-edge designs that inspire and engage. "Designs that Speak, Brands that Connect."</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="services1" class="services1">
      <div class="container">

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">Branding and Identity</h4>
              <p class="description">Discuss the components of a strong brand identity, including logo, color palette, typography, and brand voice. Offer tips on how to align these elements with a company's values and mission.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">Logo Design</h4>
              <p class="description">Outline the principles of effective logo design, such as simplicity, scalability, memorability, and relevance. Include examples of logos that embody these principles.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">Marketing Materials</h4>
              <p class="description">Offer tips for creating engaging marketing materials, such as brochures, flyers, and business cards. Explain how to align the design with the company's branding.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">UI/UX Design</h4>
              <p class="description"> Define the key principles of UI/UX design, such as usability, accessibility, and user-centered design. Offer practical examples of user-friendly interfaces.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">Illustrations and Art</h4>
              <p class="description">Discuss how illustrations can add a unique and personal touch to a brand's identity. Provide examples of companies that use illustrations effectively.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><i class="fa fa-arrow-circle-down"></i></div>
              <h4 class="title">Process Overview</h4>
              <p class="description">Explain how you work with clients, from concept to final delivery.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Cta/>
    
    </>
  )
}

export default Graphic_Designing